// Generated by Framer (4d5c76d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/cwbHx_YqQ";
import * as sharedStyle1 from "../css/dQtjpfp3b";

const cycleOrder = ["McnuSgApu"];

const serializationHash = "framer-qqDVn"

const variantClassNames = {McnuSgApu: "framer-v-10y5uu0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, number, text, width, ...props}) => { return {...props, cJKDNOGIY: text ?? props.cJKDNOGIY ?? "Years of experience", h3Bl1MAwQ: number ?? props.h3Bl1MAwQ ?? "15+"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;number?: string;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, h3Bl1MAwQ, cJKDNOGIY, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "McnuSgApu", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-10y5uu0", className, classNames)} data-framer-name={"Default"} initial={variant} layoutDependency={layoutDependency} layoutId={"McnuSgApu"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 className={"framer-styles-preset-1us62f4"} data-styles-preset={"cwbHx_YqQ"} style={{"--framer-text-color": "var(--extracted-gdpscs, var(--token-a7f7ae5f-a181-442b-8823-be0ca1b170fe, rgb(255, 255, 255)))"}}>15+</motion.h1></React.Fragment>} className={"framer-kytdxb"} data-framer-name={"Number"} layoutDependency={layoutDependency} layoutId={"wKUfXuraZ"} style={{"--extracted-gdpscs": "var(--token-a7f7ae5f-a181-442b-8823-be0ca1b170fe, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} text={h3Bl1MAwQ} verticalAlignment={"center"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-pxdku6"} data-styles-preset={"dQtjpfp3b"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-57644229-9aea-4671-a3e2-529f7b58afe0, rgb(226, 229, 222)))"}}>Years of experience</motion.p></React.Fragment>} className={"framer-oto4st"} data-framer-name={"Text"} layoutDependency={layoutDependency} layoutId={"K84VtTYCD"} style={{"--extracted-r6o4lv": "var(--token-57644229-9aea-4671-a3e2-529f7b58afe0, rgb(226, 229, 222))", "--framer-paragraph-spacing": "0px"}} text={cJKDNOGIY} verticalAlignment={"center"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-qqDVn[data-border=\"true\"]::after, .framer-qqDVn [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qqDVn.framer-1oaswry, .framer-qqDVn .framer-1oaswry { display: block; }", ".framer-qqDVn.framer-10y5uu0 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 150px; }", ".framer-qqDVn .framer-kytdxb, .framer-qqDVn .framer-oto4st { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qqDVn.framer-10y5uu0 { gap: 0px; } .framer-qqDVn.framer-10y5uu0 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-qqDVn.framer-10y5uu0 > :first-child { margin-top: 0px; } .framer-qqDVn.framer-10y5uu0 > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 107.5
 * @framerIntrinsicWidth 150
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"h3Bl1MAwQ":"number","cJKDNOGIY":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerKIPSVOdGD: React.ComponentType<Props> = withCSS(Component, css, "framer-qqDVn") as typeof Component;
export default FramerKIPSVOdGD;

FramerKIPSVOdGD.displayName = "Stats Single";

FramerKIPSVOdGD.defaultProps = {height: 107.5, width: 150};

addPropertyControls(FramerKIPSVOdGD, {h3Bl1MAwQ: {defaultValue: "15+", displayTextArea: false, title: "Number", type: ControlType.String}, cJKDNOGIY: {defaultValue: "Years of experience", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramerKIPSVOdGD, [...sharedStyle.fonts, ...sharedStyle1.fonts])